<template>
  <div>
    <div :style="{height: contentHeight + 'px'}" style="position: relative">
      <div class="box-center" style="width: 444px;">
        <w-autocomplete :items="items" label="所属类目" @change="code = $event"/>
      </div>
    </div>
    <v-divider/>
    <div class="text-center pa-4">
      <v-btn depressed color="primary" :disabled="!code" @click="$emit('changeStep', code)">下一步</v-btn>
    </div>
  </div>
</template>

<script>
import WAutocomplete from "@/components/w-autocomplete/w-autocomplete.vue";

export default {
  name: "select-category",
  components: {
    WAutocomplete
  },
  data() {
    return {
      items: [],
      contentHeight: 0,
      code: ''
    }
  },
  mounted() {
    this.axios.post(this.select_data, {keyname: 'gift'}).then(res => {
      this.items = res.data[0].values
    })
    this.contentHeight = document.documentElement.clientHeight - 56 - 24 - 99 - 72
  }
}
</script>

<style scoped>

</style>