<template>
  <div style="background: #ffffff; border-radius: 8px">
    <div class="d-flex justify-center">
      <v-stepper alt-labels :width="500" v-model="step">
        <v-stepper-header>
          <v-stepper-step
              :complete="step > 1"
              step="1">
            选择类目
          </v-stepper-step>
          <v-divider/>
          <v-stepper-step
              :complete="step > 2"
              step="2">
            图文信息
          </v-stepper-step>
          <v-divider/>
          <v-stepper-step
              :complete="step > 3"
              step="3">
            销售规格
          </v-stepper-step>
        </v-stepper-header>
      </v-stepper>
    </div>
    <v-divider/>
    <select-category
        v-if="step === 1"
        @changeStep="changeStep1"/>
    <graphic-info
        v-if="step === 2"
        :p_form="form2"
        @changeStep="changeStep2"
        @change="form2 = $event"
        :code="form1.groupid"/>
    <sales-specification
        v-if="step === 3"
        :p_form="form3"
        @change="form3 = $event"
        :code="form1.groupid"
        @changeStep="changeStep3"/>
  </div>
</template>

<script>
import selectCategory from "./edit-product-components/select-category.vue";
import graphicInfo from "./edit-product-components/graphic-info.vue";
import salesSpecification from "./edit-product-components/sales-specification.vue";

export default {
  name: "add-product",
  components: {
    selectCategory,
    graphicInfo,
    salesSpecification
  },
  data() {
    return {
      step: 1,
      form1: {
        groupid: ''
      },
      form2: {},
      form3: {}
    }
  },
  methods: {
    changeStep1(v) {
      this.form1.groupid = v
      this.step = 2
    },
    changeStep2(step) {
      this.step = step
    },
    changeStep3(step) {
      if (step) {
        this.step = step
      } else {
        let params = Object.assign({}, this.form1, this.form2, this.form3)
        this.axios.post('/api/gift/save_giftitem', params).then(res => {
          if (res.code === this.staticVal.Code.Success) {
            this.snackbar.success('商品新增成功')
            this.$router.push('/product-list')
          }
        })
      }
    }
  }
}
</script>

<style scoped lang="scss">

.v-stepper__header, .v-sheet.v-stepper:not(.v-sheet--outlined) {
  box-shadow: none;
}
</style>
